import React from 'react';
import emojis from './data';
import EmojiCard from './EmojiCard';
import './EmojiCard.scss';

function EmojiAll() {
  return (
    <div className="floater-wrap">
      {emojis.map(emoji =>
        <EmojiCard
          name={emoji.name}
          ext={emoji.ext}
        />
      )}
    </div>
  );
}

export default EmojiAll;