import React from 'react';
import PropTypes from 'prop-types';
import TinyPhoto from '../TinyPhoto/TinyPhoto';
import './EmojiCard.scss';

export default function EmojiCard({ name, ext }) {
  let extension = "gif";
  if (ext) {
    extension = ext;
  }

  const url = "/emoji/" + name + "." + extension;
  const caption = "![](/emoji/" + name + "." + extension + ")";

  return(
    <div className="floater">
      <TinyPhoto src={url} caption={caption} alt="" />
    </div>
  )
}

EmojiCard.propTypes = {
  name: PropTypes.string.isRequired,
}